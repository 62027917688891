// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NewPassword-module__newPassButton___vwXLa {\n  background-color: transparent;\n  border: none;\n  padding: 14px 25px;\n  border-radius: 4px;\n  box-shadow: 0px 3px 6px #00000029;\n  font-size: 14px;\n  font-weight: 300;\n  text-decoration: underline;\n  margin-bottom: 10px;\n  width: 100%;\n  display: flex;\n  justify-content: flex-start;\n  outline: none;\n  -webkit-appearance: none;\n}", "",{"version":3,"sources":["webpack://./react/components/Login/NewPassword.module.scss"],"names":[],"mappings":"AAGA;EACE,6BAAA;EACA,YAAA;EACA,kBAAA;EACA,kBAAA;EACA,iCAAA;EACA,eAAA;EACA,gBAAA;EACA,0BAAA;EACA,mBAAA;EACA,WAAA;EACA,aAAA;EACA,2BAAA;EACA,aAAA;EACA,wBAAA;AAFF","sourcesContent":["@import \"../../../scss/variables\";\n@import \"../../../scss/mixins\";\n\n.newPassButton {\n  background-color: transparent;\n  border: none;\n  padding: 14px 25px;\n  border-radius: 4px;\n  box-shadow: 0px 3px 6px #00000029;\n  font-size: 14px;\n  font-weight: 300;\n  text-decoration: underline;\n  margin-bottom: 10px;\n  width: 100%;\n  display: flex;\n  justify-content: flex-start;\n  outline: none;\n  -webkit-appearance: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"newPassButton": "NewPassword-module__newPassButton___vwXLa"
};
export default ___CSS_LOADER_EXPORT___;
