// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ForgotPassword-module__forgotPassButton___Ahr3v {\n  background-color: transparent;\n  border: none;\n  font-size: 14px;\n  font-weight: 300;\n  padding: 0;\n  outline: none;\n  text-decoration: underline;\n}\n\n.ForgotPassword-module__validationError___8g6MJ {\n  color: #ed1c24;\n  font-size: 0.95rem;\n}", "",{"version":3,"sources":["webpack://./react/components/Login/ForgotPassword.module.scss","webpack://./scss/_variables.scss"],"names":[],"mappings":"AAGA;EACE,6BAAA;EACA,YAAA;EACA,eAAA;EACA,gBAAA;EACA,UAAA;EACA,aAAA;EACA,0BAAA;AAFF;;AAKA;EACE,cCRW;EDSX,kBAAA;AAFF","sourcesContent":["@import \"../../../scss/variables\";\n@import \"../../../scss/mixins\";\n\n.forgotPassButton {\n  background-color: transparent;\n  border: none;\n  font-size: 14px;\n  font-weight: 300;\n  padding: 0;\n  outline: none;\n  text-decoration: underline;\n}\n\n.validationError {\n  color: $color--red;\n  font-size: 0.95rem;\n}","$max-width: 1100px;\n$border-radius: 5px;\n\n// NEW COLORS\n$color--black: #333;\n$color--white: #fff;\n$color--red: #ed1c24;\n$color--red-dark: #B61D22;\n$color--green: #62a361;\n$color--yellow: #dfc822;\n$color--light-blue: #ccdde6;\n$color--blue: #062f57;\n$color--blue-dark: #011324;\n$color--grey-light: #f8f8f8;\n$color--grey-medium: #00000029;\n$color--grey-medium2: #8e969e;\n$color--grey-dark: #303030;\n$color--btn-grey: #a8a8a8;\n$color--purple: #7B61FF;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"forgotPassButton": "ForgotPassword-module__forgotPassButton___Ahr3v",
	"validationError": "ForgotPassword-module__validationError___8g6MJ"
};
export default ___CSS_LOADER_EXPORT___;
