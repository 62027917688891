import React, {useState, useRef, useEffect} from 'react';
import Modal from 'react-modal';

import styles from './ForgotPassword.module.scss';
import modalStyles from '../Modal/Modal.module.scss';
import postDataToAPI from "../Api/postDataToAPI";
import PropTypes from "prop-types";
import BookingListRow from "../BookingList/BookingListRow";


const ForgotPassword = () => {
  const agencyId = useRef(null);
  const email = useRef(null);

  const [showForgotPassModal, setForgotPassModal] = useState(false);
  const [passwordSent, setPasswordSent] = useState(false);
  const [error, setError] = useState('');
  const [inputValidation, setInputValidation] = useState(false);
  const [emailValidation, setEmailValidation] = useState(false);
  const [modalConfirmButtonDisabled, setModalConfirmButtonDisabled] = useState(true);

  Modal.setAppElement("#password-forgot");

  function toggleModal() {
    setForgotPassModal(!showForgotPassModal);
    setPasswordSent(false);
    setModalConfirmButtonDisabled(true)
  }

  const sendPasswordRequest = () => {
    const params = {
      agencyId: agencyId.current.value,
      email: email.current.value
    };

    let query = Object.keys(params)
      .map((k) => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
      .join('&');

    postDataToAPI('/forgotpass?' + query)
    .then((data) => {
      if (data.error) {
        setError(data.message);
      }
      else {
        setPasswordSent(!passwordSent);
      }
    })
    .catch(() => {

    });
  };

  const toggleConfirmButton = () => {
    emailValidation && inputValidation ? setModalConfirmButtonDisabled(false) : setModalConfirmButtonDisabled(true);
  };

  const inputChangeHandler = (e) => {
    e.target.value.length < 3 ? setInputValidation(false) : setInputValidation(true);
    toggleConfirmButton();
  };

  const validateEmail = (e) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setEmailValidation(re.test(e.target.value));
    toggleConfirmButton();
  };

  let modalContent;
  showForgotPassModal ? (modalContent =
    <div className={modalStyles.modalContent}>
      <button className={modalStyles.closeModal} onClick={toggleModal}><img src="../../../img/icon-close.svg" alt="close" /></button>
      <div className={modalStyles.modalHeading}>
        <img src="../../../img/icon-alert-circle.svg" alt="exclamation mark" />
        <h5>{AgencyLogin.trans('Forgot your password?')}</h5>
      </div>
      {
        error ?
          <div className={styles.validationError}>
            {error}
          </div>
          : ''
      }
      <div className={modalStyles.input}>
        <label htmlFor="bureau-no">{AgencyLogin.trans('Agency number')}:</label>
        <input ref={agencyId} type="text" id="bureau-no" name="bureau-no" onChange={(e) => inputChangeHandler(e)} autoComplete="off" />
      </div>
      <div className={modalStyles.input}>
        <label htmlFor="email">{AgencyLogin.trans('Email')}:</label>
        <input ref={email} type="email" id="email" name="email" onChange={(e) => validateEmail(e)} autoComplete="off" />
      </div>

      <div className={modalStyles.modalButtons}>
        <button className={modalStyles.modalDismiss} onClick={toggleModal}>{AgencyLogin.trans('Close')}</button>
        <button className={modalStyles.modalApprove} onClick={sendPasswordRequest} disabled={modalConfirmButtonDisabled}>{AgencyLogin.trans('Send')}</button>
      </div>
    </div>)
    : null;

  passwordSent ? (modalContent =
    <div className={modalStyles.modalContent}>
      <button className={modalStyles.closeModal} onClick={toggleModal}><img src="../../../img/icon-close.svg" alt="close" /></button>
      <div className={modalStyles.modalHeading}>
        <img src="../../../img/icon-alert-circle.svg" alt="exclamation mark" />
        <h5>Thank you.</h5>
      </div>
      <p>{AgencyLogin.trans('Your request has been submitted.')}</p>
    </div>)
    : null;

  return (
    <>
      <Modal
        contentLabel={AgencyLogin.trans('Forgot Password')}
        isOpen={showForgotPassModal}
        onRequestClose={toggleModal}
        className={modalStyles.modal}
        overlayClassName={modalStyles.overlay}
      >
        {modalContent}
      </Modal>
      <button className={styles.forgotPassButton} onClick={toggleModal}>{AgencyLogin.trans('Forgot your password?')}</button>
    </>
  );
};

export default ForgotPassword;
