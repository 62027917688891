import React, { useEffect, useState } from 'react';
import fetchDataFromAPI from "../Api/fetchDataFromAPI";
import Loader from '../Loader/Loader';
import NoResults from '../NoResults/NoResults';

import styles from './BookingListDetails.module.scss';

const BookingListDetails = ({ rentalId, bookingId }) => {
  const [booking, setBooking] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);

  const fetchData = (rentalId, bookingId) => {
    let query = `/${rentalId}/${bookingId}`;
    fetchDataFromAPI('/bookings' + query)
      .then((data) => {
        if (data.error) {
          setError(true);
        }
        else {
          setBooking(data);
          setLoaded(true);
        }
      })
      .catch(() => {
        window.location = '/logout';
      });
  };

  useEffect(() => {
    fetchData(rentalId, bookingId);
  }, []);

  let documents = [];
  let payments = [];
  let extraServices = [];
  if (loaded) {
    documents = booking.documents.map((item) => <p className={styles.bookingDocument} key={item.name}><a
      href={item.url}>{item.name}</a></p>);

    payments = booking.payments.map((item) =>
      <tr key={'payment-' + item.payment}>
        <td>{item.payment}. {AgencyLogin.trans('Rate payment on')}: {item.dueDate} </td>
        <td className={styles.right}>{item.amount} {booking.paymentCurrencyCodeISO}</td>
      </tr>);

    if (booking.productRecords.length > 0) {
      extraServices = booking.productRecords.map((service) =>
        <tr key={'service-' + service.productNumber}>
          <td>{service.name}</td>
          <td>{service.totalAllUnits}</td>
          <td>{service.priceNumberOfUnits}</td>
          <td>{booking.servicesCurrency}</td>
        </tr>
      );
    }
  }

  const bookingDetails = (
    <>
      <div className={styles.statusMessage}><p className={styles.message}>{booking.messageText}</p></div>
      <div className={styles.fullBookingDetails}>
        <div className={styles.customerDetails}>
          <table className={styles.customerTable}>
            <tbody>
              <tr>
                <th colSpan="2"><h5>{AgencyLogin.trans('Customer')}</h5></th>
              </tr>
              <tr>
                <td>{AgencyLogin.trans('Name')}:</td>
                <td>{booking.name ? `${booking.title} ${booking.name}` : '-'}</td>
              </tr>
              <tr>
                <td>{AgencyLogin.trans('Address')}:</td>
                <td>{booking.address ? `${booking.address.address}, ${booking.address.address2.length > 0 ? booking.address.address2 + ',' : ''} ${booking.address.zip} ${booking.address.city}, ${booking.nationality}` : '-'}</td>
              </tr>
              <tr>
                <td>{AgencyLogin.trans('Email')}:</td>
                <td>{booking.email ? booking.email : '-'}</td>
              </tr>
              <tr>
                <td>{AgencyLogin.trans('Phone number')}:</td>
                <td>{booking.cellphone ? booking.cellphone : '-'}</td>
              </tr>
              <tr>
                <td>{AgencyLogin.trans('Guests')}:</td>
                <td>{AgencyLogin.trans('Adults: @adults, children: @children, pets: @pets', {'@adults': booking.numberOfAdult, '@children': booking.numberOfKid, '@pets': booking.numberOfPet})}</td>
              </tr>
              <tr className={styles.documents}>
                <td>{AgencyLogin.trans('Documents')}:</td>
                <td className={styles.documentList}>{booking.useEdoc ? documents : '-'}</td>
              </tr>
              <tr>
                <td>{AgencyLogin.trans('User / Agency user')}:</td>
                <td>{booking.username ? booking.username : '-'} / {booking.agencyUser ? booking.agencyUser : '-'}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className={styles.paymentDetails}>
          <table className={styles.paymentsTable}>
            <tbody>
              <tr>
                <th colSpan="2"><h5>{AgencyLogin.trans('Payment')}</h5></th>
              </tr>
              <tr>
                <td>{AgencyLogin.trans('Rent')}:</td>
                <td className={styles.right}>{booking.rentPrice} {booking.paymentCurrencyCodeISO}</td>
              </tr>
              <tr>
                <td>{AgencyLogin.trans('Supplements in total')}:</td>
                <td className={styles.right}>{booking.servicesTotal} {booking.paymentCurrencyCodeISO}</td>
              </tr>
              <tr>
                <td>{AgencyLogin.trans('Insurance specification')}:</td>
                <td className={styles.right}>{booking.insuranceFee} {booking.paymentCurrencyCodeISO}</td>
              </tr>
              <tr>
                <td className={styles.bold}>{AgencyLogin.trans('Total')}:</td>
                <td className={`${styles.bold} ${styles.right}`}>{booking.totalPayment} {booking.paymentCurrencyCodeISO}</td>
              </tr>
              <tr>
                <td>{AgencyLogin.trans('Amount paid')}:</td>
                <td className={styles.right}>{booking.paymentRegistered} {booking.paymentCurrencyCodeISO}</td>
              </tr>
              <tr>
                <td className={styles.bold}>{AgencyLogin.trans('Commission')}:</td>
                <td className={`${styles.bold} ${styles.right}`}>{booking.commission} {booking.paymentCurrencyCodeISO}</td>
              </tr>
              {payments}
            </tbody>
          </table>

          {
            extraServices.length > 0 ?
              <table className={styles.paymentsTable}>
                <tbody>
                <tr>
                  <th colSpan="2"><h5>{AgencyLogin.trans('Extra Services')}</h5></th>
                </tr>

                {extraServices}

                </tbody>
              </table>
            : ''
          }
        </div>

      </div>
    </>);

  return (
    <tr className={styles.details}>
      <td colSpan="9" className={styles.booking}>
        {error ? <NoResults /> : (loaded ? bookingDetails : <Loader />)}
      </td>
    </tr>
  );
};

export default BookingListDetails;
