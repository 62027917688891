const postDataToAPI = async (path) => {
  const res = await fetch(`/novasol/api${path}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  });
  return await res.json();
};

export default postDataToAPI;
