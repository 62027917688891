import React, {useRef, useState} from 'react';
import Modal from 'react-modal';

import modalStyles from '../Modal/Modal.module.scss';
import styles from './NewPassword.module.scss';
import postDataToAPI from "../Api/postDataToAPI";

const NewPassword = () => {
  const agencyId = useRef(null);
  const email = useRef(null);
  const message = useRef(null);

  const [showNewPassModal, setNewPassModal] = useState(false);
  const [passwordSent, setPasswordSent] = useState(false);
  const [inputValidation, setInputValidation] = useState(false);
  const [emailValidation, setEmailValidation] = useState(false);
  const [textValidation, setTextValidation] = useState(false);
  const [modalConfirmButtonDisabled, setModalConfirmButtonDisabled] = useState(true);

  Modal.setAppElement("#password-new");

  function toggleModal() {
    setNewPassModal(!showNewPassModal);
    setPasswordSent(false);
    setModalConfirmButtonDisabled(true)
  }

  const sendPasswordRequest = () => {
    const params = {
      agencyId: agencyId.current.value,
      email: email.current.value,
      message: message.current.value
    };

    let query = Object.keys(params)
      .map((k) => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
      .join('&');

    postDataToAPI('/createpass?' + query)
    .then((data) => {
      setPasswordSent(!passwordSent);
    })
    .catch(() => {

    });
  };

  const toggleConfirmButton = () => {
    emailValidation && inputValidation && textValidation ? setModalConfirmButtonDisabled(false) : setModalConfirmButtonDisabled(true);
  };

  const inputChangeHandler = (e) => {
    e.target.value.length < 3 ? setInputValidation(false) : setInputValidation(true);
    toggleConfirmButton();
  };

  const validateEmail = (e) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setEmailValidation(re.test(e.target.value));
    toggleConfirmButton()
  };

  const textChangeHandler = (e) => {
    e.target.value.length < 6 ? setTextValidation(false) : setTextValidation(true);
    toggleConfirmButton();
  };

  let modalContent;
  showNewPassModal ? (modalContent =
    <div className={modalStyles.modalContent}>
      <button className={modalStyles.closeModal} onClick={toggleModal}><img src="../../../img/icon-close.svg" alt="close" /></button>
      <div className={modalStyles.modalHeading}>
        <img src="../../../img/icon-alert-circle.svg" alt="exclamation mark" />
        <h5>{AgencyLogin.trans('New password?')}</h5>
      </div>
      <p>{AgencyLogin.trans('Please fill out the form to receive a password for your agency.')}</p>
      <div className={modalStyles.input}>
        <label htmlFor="bureau-no">{AgencyLogin.trans('Agency number')}:</label>
        <input ref={agencyId} type="text" id="bureau-no" name="bureau-no" onChange={(e) => inputChangeHandler(e)} autoComplete="off" />
      </div>
      <div className={modalStyles.input}>
        <label htmlFor="email">{AgencyLogin.trans('Email')}:</label>
        <input ref={email} type="email" id="email" name="email" onChange={(e) => validateEmail(e)} autoComplete="off" />
      </div>
      <div className={modalStyles.input}>
        <label htmlFor="message">{AgencyLogin.trans('Message')}:</label>
        <textarea ref={message} type="message" id="message" name="message" onChange={(e) => textChangeHandler(e)}></textarea>
      </div>
      <div className={modalStyles.modalButtons}>
        <button className={modalStyles.modalDismiss} onClick={toggleModal}>{AgencyLogin.trans('Close')}</button>
        <button className={modalStyles.modalApprove} onClick={sendPasswordRequest} disabled={modalConfirmButtonDisabled}>{AgencyLogin.trans('Send')}</button>
      </div>
    </div>)
    : null;

  passwordSent ? (modalContent =
    <div className={modalStyles.modalContent}>
      <button className={modalStyles.closeModal} onClick={toggleModal}><img src="../../../img/icon-close.svg" alt="close" /></button>
      <div className={modalStyles.modalHeading}>
        <img src="../../../img/icon-alert-circle.svg" alt="exclamation mark" />
        <h5>{AgencyLogin.trans('Thank you.')}</h5>
      </div>
      <p>{AgencyLogin.trans('Your NOVASOL contact will get back to you with a new password.')}</p>
    </div>)
    : null;

  return (
    <>
      <Modal
        contentLabel={AgencyLogin.trans('New Password')}
        isOpen={showNewPassModal}
        onRequestClose={toggleModal}
        className={modalStyles.modal}
        overlayClassName={modalStyles.overlay}
      >
        {modalContent}
      </Modal>
      <button className={styles.newPassButton} onClick={toggleModal}>{AgencyLogin.trans('No password?')}</button>
    </>
  );
};

export default NewPassword;
