import React from 'react';

import loaderStyles from './Loader.module.scss';

const Loader = () => {
  return (
    <div className={`${loaderStyles.loader} ${loaderStyles.loaderActive}`}>
      <div className={loaderStyles.loaderDots}>
        <span className={loaderStyles.loaderDot}></span>
        <span className={loaderStyles.loaderDot}></span>
        <span className={loaderStyles.loaderDot}></span>
        <span className={loaderStyles.loaderDot}></span>
        <span className={loaderStyles.loaderDot}></span>
      </div>
      <div className={loaderStyles.loaderLabel}>{AgencyLogin.trans('Loading')}</div>
    </div>
  );
};

export default Loader;
