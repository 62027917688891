import React from 'react';
import PropTypes from 'prop-types';

const NoResults = ({ nonTable }) => {
  const text =
    AgencyLogin.trans('Unfortunately we found no results matching selected criteria');

  return nonTable ? (
    <div className="noex-grid__noresults noex-grid-ds__noresults">
      <div className="noex-grid__noresults-icon noex-grid-ds__noresults-icon"> </div>
      <div className="noex-grid__noresults-text noex-grid-ds__noresults-text">
        <div className="noex-grid__noresults-lead noex-grid-ds__noresults-lead">
          {AgencyLogin.trans('Unfortunately we found no search results for you')}
        </div>
        <div className="noex-grid__noresults-query noex-grid-ds__noresults-query"> </div>
      </div>
    </div>
  ) : (
    <tr>
      <td colSpan="12">
        <div className="noex-datatable__noresults">
          <div className="noex-datatable__noresults-icon"> </div>
          <div className="noex-datatable__noresults-text">
            <div className="noex-datatable__noresults-lead">{text}</div>
          </div>
        </div>
      </td>
    </tr>
  );
};

NoResults.propTypes = {
  nonTable: PropTypes.bool
};

export default NoResults;
