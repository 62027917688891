import React, { useState, useRef } from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import moment from 'moment';
import BookingListDetails from './BookingListDetails';
import postDataToAPI from '../Api/postDataToAPI';

import styles from './BookingListRow.module.scss';
import modalStyles from '../Modal/Modal.module.scss';
import Loader from '../Loader/Loader';

const BookingListRow = ({ booking, updateList, prefixBookingUrl, suffixBookingUrl }) => {
  const confirmAgencyName = useRef(null);
  const cancelAgencyName = useRef(null);

  const [cancellationFee, setCancellationFee] = useState('');
  const [isCancellationFeeAvailable, setIsCancellationFeeAvailable] = useState(
    false
  );
  const [cancellationError, setCancellationError] = useState('');
  const [showBookingDetails, setShowBookingDetails] = useState(false);
  const [showConfirmBookingModal, setShowConfirmBookingModal] = useState(false);
  const [showCancelBookingModal, setShowCancelBookingModal] = useState(false);
  const [modalConfirmButtonDisabled, setModalConfirmButtonDisabled] = useState(
    true
  );
  const [showBookingConfirmedModal, setShowBookingConfirmedModal] = useState(
    false
  );

  Modal.setAppElement('#page-bookings');

  const submitData = (bookingId, action, agencyUser) => {
    let query = `/${bookingId}/${action}`;
    if (agencyUser) {
      query += `?agencyUser=${agencyUser}`;
    }

    /**
     * The idea here is that we'll invoke postDataToAPI and use .then and
     * .catch to handle success and failures. However, the actual response
     * for errors does not work as expected.
     *
     * When logging error.message to console, we do not see the error thrown
     * by postDataToAPI.
     */
    postDataToAPI('/bookings/update' + query)
      .then((data) => {
        switch (action) {
          case 'confirm':
            updateList();
            break;
          case 'verify':
            setCancellationFee(`${data.cancellationFee} ${data.currency}`),
              setIsCancellationFeeAvailable(true);
            break;
          case 'cancel':
            updateList(), setIsCancellationFeeAvailable(true);
            break;
        }

        if (data.error) {
          setCancellationError(data.error);
          setIsCancellationFeeAvailable(false);
        }
      })
      .catch((error) => {
        throw new Error(error);
      });
  };

  const bookingDetailsHandler = () => {
    setShowBookingDetails(!showBookingDetails);
  };

  const bookingDetails = showBookingDetails ? (
    <BookingListDetails
      rentalId={booking.rentalId}
      bookingId={booking.bookingId}
    />
  ) : null;

  const addActiveClass = showBookingDetails ? styles.active : '';

  const confirmBookingButton = (
    <button className={styles.confirm} onClick={toggleConfirmModal}>
      {AgencyLogin.trans('Confirm')}
    </button>
  );
  const cancelBookingButton = (
    <button className={styles.cancel} onClick={toggleCancelModal}>
      {AgencyLogin.trans('Cancel')}
    </button>
  );
  const today = moment().format('YYYY-MM-DD');
  const arrivalDateCheck = moment(today).isSameOrBefore(
    booking.arrivalDate,
    'day'
  );

  const actions =
    booking.status === 'OPTION' ? (
      <div className={`${styles.action} ${styles.option}`}>
        {confirmBookingButton}
        {cancelBookingButton}
      </div>
    ) : (booking.status === 'BOOKING' && arrivalDateCheck) ||
      booking.status === 'RESERVATION' ? (
      <div className={`${styles.action} ${styles.booking}`}>
        {cancelBookingButton}
      </div>
    ) : null;

  const bookingType =
    booking.status === 'OPTION'
      ? styles.option
      : booking.status === 'BOOKING'
      ? styles.booking
      : booking.status === 'RESERVATION'
      ? styles.reservation
      : booking.status === 'CANCELLATION'
      ? styles.cancellation
      : null;

  const fetchCancellationFee = () => {
    if (!cancellationFee) {
      submitData(booking.bookingId, 'verify', '');
    }
  };

  function toggleConfirmModal() {
    if (!showConfirmBookingModal) {
      fetchCancellationFee();
    }

    setShowConfirmBookingModal(!showConfirmBookingModal);
    setModalConfirmButtonDisabled(true);
  }

  const bookingConfirmed = () => {
    submitData(booking.bookingId, 'confirm', confirmAgencyName.current.value);
    setShowBookingConfirmedModal(true);
  };

  function toggleCancelModal() {
    if (!showCancelBookingModal) {
      fetchCancellationFee();
    }
    setShowCancelBookingModal(!showCancelBookingModal);
    setModalConfirmButtonDisabled(true);
  }

  const bookingCancelled = () => {
    submitData(booking.bookingId, 'cancel', cancelAgencyName.current.value);
    setShowCancelBookingModal(false)
  };

  const inputChangeHandler = (e) => {
    e.target.value.length < 3
      ? setModalConfirmButtonDisabled(true)
      : setModalConfirmButtonDisabled(false);
  };

  const confirmBookingModal = showConfirmBookingModal ? (
    <Modal
      contentLabel={AgencyLogin.trans('Confirm booking')}
      isOpen={showConfirmBookingModal}
      onRequestClose={toggleConfirmModal}
      className={modalStyles.modal}
      overlayClassName={modalStyles.overlay}
    >
      <div className={modalStyles.modalContent}>
        <button className={modalStyles.closeModal} onClick={toggleConfirmModal}>
          <img src='../../../img/icon-close.svg' alt='close' />
        </button>
        {!showBookingConfirmedModal ? (
          <>
            <div className={modalStyles.modalHeading}>
              <img
                src='../../../img/icon-alert-circle.svg'
                alt='exclamation mark'
              />
              <h5>{AgencyLogin.trans('Confirm booking')}</h5>
            </div>
            <p>
              {AgencyLogin.trans(
                'Please enter your name before confirmation of booking.'
              )}
            </p>
            <div className={modalStyles.input}>
              <label htmlFor='name'>Name:</label>
              <input
                ref={confirmAgencyName}
                type='text'
                id='Modal-content__confirmName'
                name='name'
                onChange={(e) => inputChangeHandler(e)}
                autoComplete='off'
              />
            </div>
            <div className={modalStyles.modalButtons}>
              <button
                className={modalStyles.modalDismiss}
                onClick={toggleConfirmModal}
              >
                {AgencyLogin.trans('Close')}
              </button>
              <button
                className={modalStyles.modalApprove}
                onClick={bookingConfirmed}
                disabled={modalConfirmButtonDisabled}
              >
                {AgencyLogin.trans('Confirm')}
              </button>
            </div>
          </>
        ) : (
          <>
            <div className={modalStyles.modalHeading}>
              <img
                src='../../../img/icon-alert-circle.svg'
                alt='exclamation mark'
              />
              <h5>{AgencyLogin.trans('Booking has been confirmed')}</h5>
            </div>
          </>
        )}
      </div>
    </Modal>
  ) : null;

  const cancelBookingModal = showCancelBookingModal ? (
    <Modal
      contentLabel='Cancel booking'
      isOpen={showCancelBookingModal}
      onRequestClose={toggleCancelModal}
      className={modalStyles.modal}
      overlayClassName={modalStyles.overlay}
    >
      <div className={modalStyles.modalContent}>
        <button className={modalStyles.closeModal} onClick={toggleCancelModal}>
          <img src='../../../img/icon-close.svg' alt='close' />
        </button>
        {isCancellationFeeAvailable ? (
          <>
            <div className={modalStyles.modalHeading}>
              <img
                src='../../../img/icon-alert-circle-red.svg'
                alt='exclamation mark'
              />
              <h5>{AgencyLogin.trans('Cancel booking')}</h5>
            </div>
            {cancellationFee ? null : <Loader />}
            <p>
              {AgencyLogin.trans('The cancellation fee is @cancellationFee', {
                '@cancellationFee': cancellationFee
              })}
            </p>
            <p>
              {AgencyLogin.trans('Do you want to confirm the cancellation of booking @bookingId? Please contact sales with cancellations due to illness', { '@bookingId': booking.bookingId })}
            </p>
            <div className={modalStyles.input}>
              <label htmlFor='name'>{AgencyLogin.trans('Name')}:</label>
              <input
                ref={cancelAgencyName}
                type='text'
                id='confirmName'
                name='name'
                onChange={(e) => inputChangeHandler(e)}
                autoComplete='off'
              />
            </div>
            <div className={modalStyles.modalButtons}>
              <button
                className={modalStyles.modalDismiss}
                onClick={toggleCancelModal}
              >
                {AgencyLogin.trans('Close')}
              </button>
              <button
                className={modalStyles.modalApprove}
                onClick={bookingCancelled}
                disabled={modalConfirmButtonDisabled}
              >
                {AgencyLogin.trans('Cancel booking')}
              </button>
            </div>
          </>
        ) : (
          <div>{cancellationError}</div>
        )}
      </div>
    </Modal>
  ) : null;

  return (
    <>
      {confirmBookingModal}
      {cancelBookingModal}

      <tr className={`${styles.bookingRow} ${addActiveClass}`}>
        <td className={`${styles.cell} ${styles.checkbox}`} scope='row'>
          <input type='checkbox' onClick={bookingDetailsHandler} />
        </td>
        <td className={styles.cell}>{booking.bookingId}</td>
        <td className={styles.cell}><a href={`${prefixBookingUrl}/p/${booking.rentalId}/${suffixBookingUrl}`} target="_blank">{booking.rentalId}</a></td>
        <td className={`${styles.cell}${styles.date}`}>{booking.period}</td>
        <td className={styles.cell}>
          {booking.customerName} ({booking.customerNationality})
        </td>
        <td className={styles.cell}>{booking.bookingDate}</td>
        <td className={styles.cell}>
          {booking.numberOfAdult} + {booking.numberOfKid} /{' '}
          {booking.numberOfPet}
        </td>
        <td className={`${styles.bookingStatus} ${bookingType}`}>
          {booking.status}
        </td>
        <td className={`${styles.cell} ${styles.cellActions}`}>{actions}</td>
      </tr>
      {bookingDetails}
    </>
  );
};

BookingListRow.propTypes = {
  booking: PropTypes.object,
  updateList: PropTypes.func
};

export default BookingListRow;
